import { Fragment } from "react";
import "../Components/Practice-Test/practiceTest.scss";


const PopoverHistory = (props) => {
    console.log(props)
    return (
        <div className='popover-history'>
            {
                props.type === 'Test' ?
                    <Fragment>
                        <div>
                            <h4>Test History</h4>
                            <p>{props.subject}</p>
                            <div className='close-details' onClick={() => props.onExitPopover()} ><img src='/images/close.svg' alt='' /></div>
                        </div>
                        <ul className='history'>
                            {
                                props?.history?.map(el => {
                                    return (
                                        <Fragment>
                                            {localStorage.getItem('role') === 'student' ?
                                                <li key={el._id}>
                                                    <p style={{ width: '50%' }}>{el?.createdAt?.split('T')[0]}</p>
                                                    <p style={{ fontWeight: 'normal', width: '25%' }}>{el?.correct}</p>
                                                </li> :
                                                <li key={el._id}>
                                                    <p style={{ width: '50%' }}>{el?.date?.split('T')[0]}</p>
                                                    <p style={{ fontWeight: 'normal', width: '25%' }}>{el?.score}</p>
                                                    <p style={{ fontWeight: 'normal', width: '25%' }}>{el.percentage}%</p>
                                                </li>
                                            }
                                        </Fragment>
                                    )
                                })
                            }
                        </ul >
                    </Fragment>
                    :
                    <Fragment>
                        <h4 className="d-flex justify-content-between text-left pl-2">Quiz history
                            <div onClick={() => props.onExitPopover()} style={{ marginTop: 5, cursor: 'pointer' }} ><img src='/images/close1.svg' alt='' /></div>
                        </h4>
                        {props?.history?.length ?
                            props?.history?.map((el1, i) => {
                                return (
                                    <Fragment>
                                        <div key={el1._id} className='mt-2'>
                                            <p className="text-left pl-2">{el1.title}</p>
                                        </div>
                                        <ul className='history'>
                                            {
                                                el1.history.map((el, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <p style={{ width: '50%' }}>{el?.date?.split('T')[0]}</p>
                                                            <p style={{ fontWeight: 'normal', width: '25%' }}>{el.score}</p>
                                                            <p style={{ fontWeight: 'normal', width: '25%' }}>{el.percentage}%</p>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul >
                                    </Fragment>
                                )
                            })
                            :
                            <p className="text-left pl-2">There is no quiz Data</p>
                        }
                    </Fragment>

            }

        </div >

    )
}

export default PopoverHistory