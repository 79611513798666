/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Fragment, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./manageContent.scss";
import AddModal from "./Manage-Add-Content/addModal";
import AddSubjectModal from "./Manage-Add-Content/addSubjectModal";
import Switch from "react-switch";
import { clearErrors } from '../../Redux/ActionCreators/errorAction';
import {
    resetTabs, getActiveBtn, openAddProgram, backToProgram,
    getCourseList, savedClassroom, updateClassroom, updateStatusClassroom, confirmDeleteClassroom,
    getSubjectList, getNextSubjectList, savedSubject, updateSubject, updateStatusSubject, confirmDelete, getAllSubjects,
    getPracticeTestList, getFilterTest,
    deleteQuizQuestions, savedQuestions, openAddSubject, handleModalClose, changeQuestionStatus, changeTestStatus,
    showSideBar, savedNewQuiz, resetLoader, getQuizQuestion, editedQuiz, getQuestionForEdit, editedQuestion, resetPercentage,
    getClubbedQuestionList, getQuestionFromSubject, getFilterSubject, getSearchQuestion, getChapterOfSub, getSubjectFromClassroom,
} from '../../Redux/ActionCreators/manageContentAction';
import { TopicLoader } from "../../Loader/loader";
import Select, { components } from 'react-select';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../../utils/confirmModal";
import InfiniteScroll from 'react-infinite-scroll-component';



const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <img src='/images/select-indicator.svg' alt='' />
        </components.DropdownIndicator>
    );
};

function ManageContent(props) {
    const [activeBtn, setActiveBtn] = useState("course");
    const [page, setPage] = useState(1);
    const [isFocused, setIsFocused] = useState(false);
    const [questionQuery, setQuestionQuery] = useState('');
    const [subject, setSubject] = useState('');
    const [edit, setEdit] = useState(false);
    const [details, setDetails] = useState('')
    const [search, setSearch] = useState('');
    const [query, setQuery] = useState('');
    const [isOpenFilter, setIsOpenFilter] = useState(false);
    const [loading, setloading] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [testQuery, setTestQuery] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        activeButton, isClassLoading, courseList, subjectList, selectFromSubject, isAddSubject, editSubject, editObj, isShow, questionList, questionListForQuiz, practiceList, clubQuestionList, isViewed, isQuestionLoading, subjectChapters, editQuestionData
    } = useSelector(state => state.manageContent);


    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        const firstRender = async () => {
            dispatch(resetTabs());
            dispatch(clearErrors());
            dispatch(showSideBar());
            localStorage.getItem('role') === 'state-admin' && setActiveBtn('course')
            if (activeButton === "subject") {
                setActiveBtn('subject');
                await dispatch(getSubjectList(1, search));
            }
            else {
                dispatch(getCourseList());
            }
        }

        firstRender();

        return (() => {
            // deleteQuizQuestions();
        })
    }, [])

    const onBtnChange = async (e) => {
        await dispatch(getActiveBtn(e));
        await dispatch(resetLoader());
        setActiveBtn(e);
        setSubject('');
        setQuestionQuery('');
        setPage(1);
        if (e === "course") {
            dispatch(getCourseList());
        } else if (e === "subject") {
            await dispatch(getSubjectList(1, search));
        } else if (e === "question-bank") {
            await dispatch(getClubbedQuestionList());
            await dispatch(getAllSubjects());
        } else if (e === "practice-test") {
            await dispatch(getPracticeTestList('practice', ''));
        }
    };

    const fetchData = () => {
        setPage(page + 1);
        dispatch(getNextSubjectList(page + 1, search))
    }


    const timerRef1 = React.useRef(null);


    const searchQuery = (val) => {
        clearTimeout(timerRef1.current);
        setSearch(val);
        setPage(1);
        timerRef1.current = setTimeout(() => delaySearch(val), 800);
    };

    const delaySearch = async (val) => {
        await dispatch(getSubjectList(page, val));
    }

    useEffect(() => {
        if (activeBtn === 'question-bank') {
            setDetails(editQuestionData);
        }
    }, [editQuestionData])


    const addPrograms = async (el) => {
        if (el) {
            setEdit(true);
            if (activeBtn === 'practice-test') {
                dispatch(getQuizQuestion(el._id));
                setDetails(el);
                setEdit(true);
            }
            else if (activeBtn === 'question-bank') {
                await dispatch(getQuestionForEdit('Question', el));
                dispatch(getAllSubjects());
                setEdit(true);
            }
            else {
                setDetails(el);
                setEdit(true);
            }
        } else {
            if (activeBtn === 'question-bank' || activeBtn === 'practice-test') {
                dispatch(getAllSubjects());
            }
            setEdit(false);
            setDetails(null);
        }
        dispatch(openAddProgram());
    };


    const backToMain = () => {
        dispatch(backToProgram());
        setDetails('');
    };


    const handleModal = async () => {
        await dispatch(handleModalClose());
    };

    const goToSubject = (id, classroom) => {
        navigate("/manage-content/subject", {
            state: { id, classroom },
        });
    };


    const handleClassroomStatus = (detail) => {
        let obj = {
            course_id: detail?._id,
            active: !detail.active
        }
        dispatch(updateStatusClassroom(obj));
    }

    const handleSubjectStatus = (detail) => {
        let obj = {
            subject_id: detail?._id,
            active: !detail.active
        }
        dispatch(updateStatusSubject(obj));
    }

    const goToChapter = (id, title, details) => {
        navigate("/manage-content/chapter", {
            state: { id: id, title: title, details: details, subject: true },
        });
    };

    const openDelete = (detail) => {
        setIsConfirm(true);
        setDeleteId(detail._id);
    }


    const handleTestStatus = (id, checked) => {
        let obj = {
            quiz_id: id,
            active: !checked
        }
        dispatch(changeTestStatus(obj));
    }

    const handleQuestionStatus = (id, checked) => {
        let obj = {
            question_id: id,
            active: !checked
        }
        dispatch(changeQuestionStatus(obj));
    }



    const checkFocus = () => {
        // dummy element
        let dummyEl = document.getElementById("express-form-typeahead");
        // check for focus
        let isFocused = document.activeElement === dummyEl;

        if (isFocused) {
            setIsFocused(isFocused);
        } else {
            setIsFocused(false);
        }
    };


    const selectSubject = (e) => {
        setSubject(e);
    }



    const searchQuestions = (e) => {
        setQuery(e.target.value);
        dispatch(getSearchQuestion(e.target.value));
    };

    const searchQuestionSubject = (val) => {
        setQuestionQuery(val);
        dispatch(getFilterSubject(val));
    }

    const searchPracticeTest = (val) => {
        setTestQuery(val);
        dispatch(getFilterTest(val));
    }


    const openFilter = () => {
        setIsOpenFilter(true);
    }

    const applyFilter = async () => {
        await dispatch(getQuestionFromSubject(subject?.value, 'mcq'));
        setIsOpenFilter(false);
    }



    const viewQuestions = async (el) => {
        setloading(true);
        await dispatch(getQuestionFromSubject(el._id, 'mcq'));
        window.scrollTo(0, 0);
        setloading(false);
        setSubject({ label: el.title, value: el._id });
    }


    return (
        <Fragment>

            {<div className="mobile-coming">
                <div className="mobile-inner-hide">
                    <img src="/images/mobile-error.svg" alt='' />
                    <h4>Please visit the website on your Desktop/Laptop</h4>
                    <p>Mobile view coming soon</p>
                </div>

            </div>}
            {!isShow ? (
                <div className="manage-content-holder">
                    <h1>Manage Content</h1>
                    {
                        <div className="content-tabs">
                            <button
                                className={
                                    activeBtn === "course" ? "active-btn" : null
                                }
                                onClick={() => onBtnChange("course")}
                            >
                                Courses
                            </button>
                            &nbsp;
                            <button
                                className={
                                    activeBtn === "subject" ? "active-btn" : null
                                }
                                onClick={() => onBtnChange("subject")}
                            >
                                Subjects
                            </button>
                            &nbsp;
                            <button
                                className={
                                    activeBtn === "practice-test" ? "active-btn" : null
                                }
                                onClick={() => onBtnChange("practice-test")}
                            >
                                Practice Tests
                            </button>
                            &nbsp;
                            <button
                                className={
                                    activeBtn === "question-bank" ? "active-btn" : null
                                }
                                onClick={() => onBtnChange("question-bank")}
                            >
                                Question Bank
                            </button>

                        </div>
                    }
                    {
                        activeBtn === "course" ? (
                            <Fragment>

                                <div className="content-header">
                                    {

                                        <Fragment>
                                            {localStorage.getItem('role')?.includes('state-admin') ? null :
                                                <button onClick={() => addPrograms(null)}>
                                                    <img src="/images/plus-white.svg" alt="" /> &nbsp; Add
                                                    Course
                                                </button>}
                                            <div class="animated-search d-none">
                                                <input
                                                    id="express-form-typeahead"
                                                    autoComplete="off"
                                                    onBlur={checkFocus}
                                                    onFocus={checkFocus}
                                                    spellCheck="false"
                                                    type="text"
                                                />
                                                <div class="symbol">
                                                    <img src="/images/search1.svg" alt="" />
                                                </div>
                                                {isFocused ? (
                                                    <img
                                                        src="/images/close2.png"
                                                        alt=""
                                                        className="close-input"
                                                        onClick={() => setIsFocused(false)}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </Fragment>
                                    }

                                    <div className="content-heading">
                                        <ul>
                                            <li>
                                                <div>Course</div>
                                                <div>Affliation</div>
                                                <div>Class</div>
                                                <div>Subjects</div>
                                                <div>Status</div>
                                                {localStorage.getItem('role')?.includes('state-admin') ? null : <div>Actions</div>}
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {isClassLoading ? (
                                    <div>
                                        <TopicLoader />
                                    </div>
                                ) : courseList !== undefined && courseList.length > 0 ? (
                                    <ul className='program-list'>
                                        {
                                            courseList !== undefined && courseList.map((el, index) => {
                                                return (
                                                    <li key={index}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            goToSubject(el._id, el.name)
                                                        }}>

                                                        <div className='program-name text-capitalize d-flex align-items-center' >
                                                            <div style={{ width: '56px' }}>
                                                                <img src={el.image} className='rounded' alt='' width='56px' height='32px' />
                                                            </div> &nbsp;&nbsp;
                                                            <p>{el.name}</p>
                                                        </div>

                                                        <div className="text-center">
                                                            <p style={{ color: '#696873' }}>{el.affiliation?.name}</p>
                                                        </div>

                                                        <div className="text-center">
                                                            <h3>{el.class}</h3>
                                                        </div>
                                                        <div className="text-center">
                                                            <h3>{el.subjects}</h3>
                                                        </div>
                                                        <div className="text-center">
                                                            {localStorage.getItem('role')?.includes('state-admin') ? (el.active ? "Active" : "Inactive") :
                                                                <p
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        handleClassroomStatus(el);
                                                                    }}
                                                                >
                                                                    <Switch
                                                                        width={30}
                                                                        height={16}
                                                                        uncheckedIcon={false}
                                                                        checkedIcon={false}
                                                                        onColor="#00B24B"
                                                                        checked={el.active}
                                                                        onChange={(e) => { console.log(e) }}
                                                                    />
                                                                    <br />
                                                                    {el.active ? "Active" : "Inactive"}
                                                                </p>}
                                                        </div>
                                                        {localStorage.getItem('role')?.includes('state-admin') ? null :
                                                            <div className='text-center'>
                                                                <img src='/images/edit.svg' alt='' onClick={(e) => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    addPrograms(el)
                                                                }} /> &nbsp;&nbsp;
                                                                {el.active ? null : <img
                                                                    src="/images/delete.svg"
                                                                    alt=""
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        openDelete(el)
                                                                    }}
                                                                />}
                                                                {/* <img src='/images/menu.svg' alt='' /> */}
                                                            </div>}
                                                    </li >
                                                )
                                            })
                                        }

                                    </ul >) : (
                                    <div className="empty-list">
                                        <img src="/images/empty.png" alt="" />
                                        <p>You have not added any courses yet.</p>
                                    </div>
                                )}
                            </Fragment>
                        ) :
                            activeBtn === "subject" ? (
                                <Fragment>
                                    <div className="content-header">
                                        <Fragment>
                                            <button
                                                onClick={() => dispatch(openAddSubject(null, false))}
                                            >
                                                <img src="/images/plus-white.svg" alt="" /> &nbsp; Add
                                                Subject
                                            </button>
                                            <div class="animated-search">
                                                <input
                                                    id="express-form-typeahead"
                                                    autoComplete="off"
                                                    onBlur={checkFocus}
                                                    onFocus={checkFocus}
                                                    spellCheck="false"
                                                    type="text"
                                                    value={search}
                                                    onChange={(e) => searchQuery(e.target.value)}
                                                />
                                                <div class="symbol">
                                                    <img src="/images/search1.svg" alt="" />
                                                </div>
                                                {isFocused ? (
                                                    <img
                                                        src="/images/close2.png"
                                                        alt=""
                                                        className="close-input"
                                                        onClick={() => setIsFocused(false)}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                        </Fragment>
                                        <div className="content-heading">
                                            <ul>
                                                <li>
                                                    <div>Subjects</div>
                                                    <div>Affiliation</div>
                                                    <div>Chapters</div>
                                                    <div>Items</div>
                                                    <div>Status</div>
                                                    <div>Actions</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {isClassLoading ? (
                                        <div>
                                            <TopicLoader />
                                        </div>
                                    ) : subjectList !== undefined && subjectList?.length > 0 ? (
                                        <InfiniteScroll
                                            dataLength={subjectList && subjectList?.length} //This is important field to render the next data
                                            next={fetchData}
                                            hasMore={true}
                                        >
                                            <ul className="program-list">
                                                {
                                                    subjectList?.map((el, index) => {
                                                        return (
                                                            <li
                                                                key={el._id}
                                                                onClick={() =>
                                                                    goToChapter(el._id, el.name, el)
                                                                }
                                                            >
                                                                <div className='program-name text-capitalize d-flex align-items-center'>
                                                                    <div style={{ width: '56px' }}>
                                                                        <img src={el.image} className='rounded' alt='' width='56px' height='32px' />
                                                                    </div> &nbsp;&nbsp;
                                                                    <p>{el.name}</p>
                                                                </div>
                                                                <div className="text-center" style={{ fontFamily: 'Roboto' }}>
                                                                    <p style={{ color: '#696873' }}>{el.affiliation?.name}</p>
                                                                </div>
                                                                <div className="text-center">
                                                                    <h3>{el?.chapters}</h3>
                                                                </div>


                                                                <div className="text-center">
                                                                    <h3>{el.articles + el.videos + el.quizzes + el.documents}</h3>
                                                                </div>
                                                                {localStorage.getItem('role') === 'sme' ?
                                                                    <div className="text-center">
                                                                        <p> {el.active ? "Active" : "Inactive"}</p>
                                                                    </div>
                                                                    :
                                                                    <div className="text-center">
                                                                        <p
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                                handleSubjectStatus(el);
                                                                            }}
                                                                        >
                                                                            <Switch
                                                                                width={30}
                                                                                height={16}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                onColor="#00B24B"
                                                                                checked={el.active}
                                                                                onChange={(e) => { console.log(e) }}
                                                                            />
                                                                            <br />
                                                                            {el.active ? "Active" : "Inactive"}
                                                                        </p>
                                                                    </div>
                                                                }
                                                                <div className="text-center">
                                                                    <img
                                                                        src="/images/edit.svg"
                                                                        alt=""
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            dispatch(openAddSubject(el, true));
                                                                        }}
                                                                    />{" "}
                                                                    &nbsp;&nbsp;
                                                                    {el.active ? null : <img
                                                                        src="/images/delete.svg"
                                                                        alt=""
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            openDelete(el)
                                                                        }}
                                                                    />}
                                                                    {/* &nbsp;&nbsp;
                                                                <img
                                                                    src="/images/menu.svg"
                                                                    alt=""
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                    }}
                                                                /> */}
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        </InfiniteScroll>
                                    ) : (
                                        <div className="empty-list">
                                            <img src="/images/empty.png" alt="" />
                                            <p>You have not added any subjects yet.</p>
                                        </div>
                                    )}
                                </Fragment>
                            ) : activeBtn === "question-bank" ? (
                                !isViewed ?
                                    <Fragment>
                                        <div className="content-header">
                                            <div className='d-flex justify-content-between align-items-center' >
                                                <button onClick={() => addPrograms(null)}><img src="/images/plus-white.svg" alt="" /> &nbsp; Add Questions</button> &nbsp; &nbsp;
                                                <div className="has-search"  >
                                                    <span className="form-control-feedback">
                                                        <img src="/images/search1.svg" width="14" alt='' />
                                                    </span>
                                                    <input type='search' name='search' placeholder='' value={questionQuery} id='search' onChange={(e) => searchQuestionSubject(e.target.value)} autoComplete='off' className='form-control' />
                                                </div>
                                            </div>
                                            <div className="content-heading">
                                                <ul>
                                                    <li>
                                                        <div style={{ width: "20%", paddingLeft: 15 }}>
                                                            Subject
                                                        </div>
                                                        <div style={{ width: "20%", paddingLeft: 15 }}>
                                                            Questions
                                                        </div>
                                                        <div>MCQ</div>
                                                        <div>Subjective</div>
                                                        <div>
                                                            Actions
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {
                                            isQuestionLoading ? (
                                                <div>
                                                    <TopicLoader />
                                                </div>
                                            ) :
                                                clubQuestionList !== undefined && clubQuestionList.length > 0 ? (

                                                    <ul className="program-list">
                                                        {
                                                            clubQuestionList.map((el, index) => {
                                                                return (
                                                                    <li key={el._id} style={{ cursor: 'default' }}>
                                                                        <div className="program-name" style={{ width: "20%" }}>
                                                                            <p>{el.name}</p>
                                                                        </div>
                                                                        <div style={{ width: "20%", textAlign: 'center' }}>
                                                                            <h3>{el.total_count}</h3>
                                                                        </div>
                                                                        <div
                                                                            className="program-name text-center"
                                                                            style={{ width: "20%" }}
                                                                        >
                                                                            <h3>{el.total_mcq}</h3>
                                                                        </div>
                                                                        <div
                                                                            className="program-name text-center"
                                                                            style={{ width: "20%" }}
                                                                        >
                                                                            <h3>{el.total_essay}</h3>
                                                                        </div>

                                                                        <div className="text-center cursor-pointer" onClick={() => el.total_count === 0 ? '' : viewQuestions(el)} style={{ color: el.total_count === 0 ? '#696873' : '#5855d6', pointerEvents: el.total_count === 0 ? 'none' : 'auto', fontWeight: 600 }}>
                                                                            View Questions
                                                                        </div>
                                                                    </li>
                                                                );
                                                            })}
                                                    </ul>
                                                ) : (
                                                    <div className="empty-list">
                                                        <img src="/images/empty.png" alt="" />
                                                        <p>You have not added any Questions yet.</p>
                                                    </div>
                                                )
                                        }
                                    </Fragment>
                                    : <Fragment>
                                        <div className="content-header">
                                            <div className='d-flex justify-content-between align-items-center' style={{ width: '90%' }}>
                                                <div className="d-flex align-items-center">
                                                    <button onClick={() => dispatch(resetLoader())} style={{ minWidth: 120 }}><img src="/images/back-white.svg" alt="" /> &nbsp; Back</button> &nbsp; &nbsp;
                                                    <button onClick={() => addPrograms(null)}><img src="/images/plus-white.svg" alt="" /> &nbsp; Add Questions</button> &nbsp; &nbsp;
                                                </div>
                                                <div className="has-search"  >
                                                    <span className="form-control-feedback">
                                                        <img src="/images/search1.svg" width="14" alt='' />
                                                    </span>
                                                    <input type='search' name='student' placeholder='' value={query} id='search' onChange={(e) => searchQuestions(e)} autoComplete='off' className='form-control' />
                                                </div>
                                                <div className='d-flex align-items-center cursor-pointer' style={{ marginLeft: '10%' }} onClick={openFilter}>
                                                    <img src='/images/filter-icon.svg' alt='' /> &nbsp;<p>Filters</p>
                                                </div>
                                            </div>

                                            <div className="content-heading">
                                                <ul>
                                                    <li>
                                                        <div style={{ width: "10%", paddingLeft: 15 }}>
                                                            Type
                                                        </div>
                                                        <div style={{ width: "45%", paddingLeft: 15 }}>
                                                            Title
                                                        </div>
                                                        {/* <div>Chapter</div> */}
                                                        {/* <div>Tags</div> */}
                                                        <div>Status</div>
                                                        <div >
                                                            Actions
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        {loading ? (
                                            <div>
                                                <TopicLoader />
                                            </div>
                                        ) :
                                            questionList !== undefined && questionList.length > 0 ? (

                                                <ul className="program-list">
                                                    {questionList !== undefined &&
                                                        questionList.map((el, index) => {
                                                            return (
                                                                <li key={index} style={{ cursor: 'default' }}>
                                                                    <div className="program-name" style={{ width: "10%" }}>
                                                                        <p>{el.type === 'mcq' ? 'MCQ' : "ESSAY"}</p>
                                                                    </div>
                                                                    <div style={{ width: "45%" }}>
                                                                        <p dangerouslySetInnerHTML={{ __html: el.description }} />
                                                                    </div>
                                                                    {/* <div
                                                                        className="text-center"
                                                                        style={{ width: "20%" }}
                                                                    >
                                                                        <p>
                                                                            {el.chapter_name && el.chapter_name}
                                                                        </p>
                                                                    </div> */}
                                                                    {/* <div
                                                                        className="text-center"
                                                                        style={{ width: "20%" }}
                                                                    >
                                                                        <p>{el.tags && el.tags.map(e => e).join(', ')}</p>
                                                                    </div> */}
                                                                    <div className="text-center">
                                                                        <p onClick={(e) => handleQuestionStatus(el._id, el.active)}>{" "}
                                                                            <Switch
                                                                                checked={el.active}
                                                                                width={30}
                                                                                height={16}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                onColor="#00B24B"
                                                                                onChange={(e) => { console.log(e) }}
                                                                            />
                                                                            <br />
                                                                            {el.active ? 'Active' : 'Inactive'}
                                                                        </p>
                                                                    </div>
                                                                    <div className="text-center cursor-pointer">
                                                                        <img src="/images/edit.svg" alt="" onClick={() => addPrograms(el)} />{" "}
                                                                    </div>
                                                                </li>
                                                            );
                                                        })}
                                                </ul>
                                            ) : (
                                                <div className="empty-list">
                                                    <img src="/images/empty.png" alt="" />
                                                    <p>You have not added any Questions yet.</p>
                                                </div>
                                            )}
                                    </Fragment>

                            ) : activeBtn === "practice-test" ? (
                                <Fragment>
                                    <div className="content-header">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <button onClick={() => addPrograms(null)}>
                                                <img src="/images/plus-white.svg" alt="" /> &nbsp;Add
                                                Practice Test
                                            </button>
                                            <div className="has-search"  >
                                                <span className="form-control-feedback">
                                                    <img src="/images/search1.svg" width="14" alt='' />
                                                </span>
                                                <input type='search' name='search' placeholder='' value={testQuery} id='search' onChange={(e) => searchPracticeTest(e.target.value)} autoComplete='off' className='form-control' />
                                            </div>
                                        </div>
                                        <div className="content-heading">
                                            <ul>
                                                <li>
                                                    <div style={{ width: '35%' }}>Title</div>
                                                    <div style={{ width: '30%' }}>Subjects</div>
                                                    <div>Questions</div>
                                                    <div>Duration(m)</div>
                                                    <div>Status</div>
                                                    <div>
                                                        Actions
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    {isClassLoading ? (
                                        <div>
                                            <TopicLoader />
                                        </div>
                                    ) :
                                        practiceList !== undefined && practiceList?.length > 0 ? (
                                            <ul className="program-list">
                                                {practiceList !== undefined &&
                                                    practiceList.map((el) => {
                                                        return (
                                                            <li key={el._id} style={{ cursor: 'default' }}>
                                                                <div className="program-name" style={{ width: '35%' }}>
                                                                    <p>{el.title}</p>
                                                                </div>

                                                                <div className="text-center" style={{ width: '30%' }}>
                                                                    <p>{el?.subjects?.map(el => el.name).join(', ')}</p>
                                                                </div>

                                                                <div className="text-center">
                                                                    <h3>{el.questions}</h3>
                                                                </div>
                                                                <div className="text-center">
                                                                    <h3>{el.time}</h3>
                                                                </div>
                                                                <div className="text-center">
                                                                    <p onClick={(e) => handleTestStatus(el._id, el.active)}
                                                                    >
                                                                        {" "}
                                                                        <Switch
                                                                            onChange={(e) => { console.log(e) }}
                                                                            checked={el.active}
                                                                            width={30}
                                                                            height={16}
                                                                            uncheckedIcon={false}
                                                                            checkedIcon={false}
                                                                            onColor="#00B24B"
                                                                        />
                                                                        <br />
                                                                        {el.active ? 'Active' : "Inactive"}
                                                                    </p>
                                                                </div>
                                                                <div className="text-center cursor-pointer">
                                                                    <img src="/images/edit.svg" alt="" onClick={() => addPrograms(el)} /> &nbsp;&nbsp;
                                                                    {/* <img src="/images/delete.svg" alt="" />
                            &nbsp;&nbsp;
                            <img src="/images/menu.svg" alt="" /> */}
                                                                </div>
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        ) : (
                                            <div className="empty-list">
                                                <img src="/images/empty.png" alt="" />
                                                <p>You have not added any Practice test yet.</p>
                                            </div>
                                        )}
                                </Fragment>
                            )
                                : null
                    }
                </div>
            ) : (
                <div>
                    <AddModal
                        type={activeBtn}
                        items={activeBtn === 'question-bank' ? editQuestionData : details}
                        edit={edit}
                        backToMain={backToMain}
                        savedClassroom={(obj) => dispatch(savedClassroom(obj))}
                        updateClassroom={(obj) => dispatch(updateClassroom(obj))}
                        resetPercentage={() => dispatch(resetPercentage())}
                        selectFromSubject={selectFromSubject}
                        savedQuestions={(obj) => dispatch(savedQuestions(obj))}
                        getChapterOfSub={(id) => dispatch(getChapterOfSub(id))}
                        questionListForQuiz={questionListForQuiz}
                        deleteAllQuestion={(obj) => dispatch(deleteQuizQuestions(obj))}
                        editedQuestion={async (obj) => {
                            await dispatch(editedQuestion(obj))
                            await dispatch(getSubjectFromClassroom(subject?.value))
                        }}
                        savedNewQuiz={(obj, type) => dispatch(savedNewQuiz(obj, type))}
                        editPracticeTest={(obj, type) => dispatch(editedQuiz(obj, type))}

                        subjectTopicList={props.subjectTopicList}
                        subjectChapters={subjectChapters}
                        getClubbedQuestionList={() => dispatch(getClubbedQuestionList())}
                        getQuizQuestion={(id) => dispatch(getQuizQuestion(id))}

                    />
                </div>
            )}


            {/* ################## Add subject modal ############################ */}
            <Modal
                show={isAddSubject}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"add-subject-modal"}
            >
                <AddSubjectModal
                    handleModal={handleModal}
                    savedSubject={(obj) => dispatch(savedSubject(obj))}
                    updateSubject={(obj) => dispatch(updateSubject(obj))}
                    items={editObj}
                    edit={editSubject}
                />
            </Modal>

            {/* ################## subject filter modal ############################ */}
            <Modal
                show={isOpenFilter}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={"update-modal1"}
            >
                <Modal.Body style={{ padding: '22px 40px 22px 40px' }}>
                    <div>
                        <label>Subject</label>
                        <div className="mt-2">
                            <Select
                                components={{ DropdownIndicator }}
                                options={selectFromSubject}
                                onChange={selectSubject}
                                placeholder='Select Subject'
                                value={subject}
                            />
                        </div>
                    </div>
                    <div className='save-btn'>
                        <button onClick={() => applyFilter()}>Apply</button>
                        <button onClick={() => setIsOpenFilter(false)}>Cancel</button>
                    </div>
                </Modal.Body>
            </Modal>

            {/* ################## Confirm Delete modal ############################ */}
            <ConfirmModal
                isConfirm={isConfirm}
                confirmDelete={async () => {
                    activeBtn === 'subject' ? await dispatch(confirmDelete(deleteId)) : await dispatch(confirmDeleteClassroom(deleteId))
                    await setIsConfirm(false)
                }}
                closeModal={() => setIsConfirm(false)}
                type={activeBtn}
            />
        </Fragment>
    );
}


export default ManageContent