import { getUniqueObjects, mergeArrays } from "../../utils/miscelleneous";
import {
    GET_STUDENT_COURSE, GET_SUBJECTS, GET_OTHER_SUBJECTS, GET_SUBJECT_CHAPTERS, GET_STUDENT_SUBJECT_BRIEF, GET_CHAPTER_TOPICS, RESET_TOPIC_LOADING,
    RESET_SUBJECT, GET_TOPIC_RESOURCES, GET_INDIVIDUAL_RESOURCE, LIKE_RESOURCE, GET_NOTE, SAVE_NOTE,
    GET_QUIZ_DATA, GET_QUESTION, GET_ANSWER, QUIZ_SUBMIT, CLOSE_QUIZ, RESET_RESOURCE, ANALYTIC_CAPTURE,
    GET_ACTIVE_SEM, GET_OTHER_PROGRAMS, GET_SEMESTERS, GET_STUDENT_DETAILS, BACK_TO_TOPIC
} from "../Types/types";


let intialState = {
    isSubjectLoading: true,
    isChapterLoading: false,
    topicLoading: true,
    mentors: [{
        "_id": "618c02d1f484f51688cd5269",
        "profile_image": "https://elite-profile-image.s3.ap-south-1.amazonaws.com/Mr.%20MANIGANDAN_1636791139262.jpg",
        "color": "#6457e8",
        "initial": "M.",
        "name": "MANIGANDAN .",
        "subjects": [
            {
                "_id": "6133d60d3c1f0585c058fad4",
                "title": "Robotics"
            }
        ]
    },
    {
        "_id": "618c04631aff77166c8d8a49",
        "profile_image": "https://elite-profile-image.s3.ap-south-1.amazonaws.com/Ramakrishnanand_1646907937553.jpg",
        "color": "#6457e8",
        "initial": "M.",
        "name": "Ramakrishnanand . S",
        "subjects": [
            {
                "_id": "6133d6033a041c6abb9d332e",
                "title": "Computer Programming (C,C++)"
            }
        ]
    }, {
        "_id": "618d405181bd6e1c606c9a34",
        "profile_image": "https://elite-profile-image.s3.ap-south-1.amazonaws.com/RENJITH_1636903380208.jpg",
        "color": "#6457e8",
        "initial": "MR",
        "name": "RENJITH REGHU",
        "subjects": [
            {
                "_id": "6133d6023a041c6abb9d31cc",
                "title": "Applied Science"
            }
        ]
    }, {
        "_id": "618d408581bd6e1c606c9a36",
        "profile_image": "https://elite-profile-image.s3.ap-south-1.amazonaws.com/Shyam_1646297503361.png",
        "color": "#6457e8",
        "initial": "MM",
        "name": "Shyam Surya",
        "subjects": [
            {
                "_id": "618d10a567cd061c88768988",
                "title": "Jigs and Fixtures"
            },
            {
                "_id": "62389a579980d11cb71b3a6a",
                "title": "Mould Technology (Sem 4)"
            }
        ]
    }, {
        "_id": "618e7e3a7f0a452a175904e8",
        "profile_image": "https://elite-profile-image.s3.ap-south-1.amazonaws.com/Bhuvana_1646027023887.png",
        "color": "#6457e8",
        "initial": "BK",
        "name": "Bhuvana K",
        "subjects": [
            {
                "_id": "618d0e2be355251c9b411634",
                "title": "Data Structure Through C"
            }
        ]
    }],
    subject: [],
    note: null,
    subjectDescription: {},
}




export default function myLearningReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_STUDENT_COURSE:
            if (!action.payload.length) {
                stateCopy.isSubjectLoading = false;
            }
            return stateCopy;
        case GET_SUBJECTS:
            stateCopy.subject = action.payload;
            stateCopy.isSubjectLoading = false;
            stateCopy.isChapterLoading = true;
            return stateCopy;
        case GET_OTHER_SUBJECTS:
            stateCopy.otherSubjects = action.payload;
            return stateCopy
        case GET_SUBJECT_CHAPTERS:
            stateCopy.subjectChapters = action.payload;
            stateCopy.isChapterLoading = false;
            return stateCopy;
        case GET_STUDENT_SUBJECT_BRIEF:
            stateCopy.subjectDescription = action.payload;
            return stateCopy;
        case GET_CHAPTER_TOPICS:
            stateCopy.chapterTopics = action.payload.sort((a, b) => { return a.index - b.index });
            stateCopy.topicLoading = false;
            return stateCopy;
        case RESET_TOPIC_LOADING:
            stateCopy.resources = [];
            stateCopy.topicLoading = true;
            return stateCopy;
        case GET_TOPIC_RESOURCES:
            var sortArr = action.payload.data.sort((a, b) => { return a.index - b.index });
            var uniquesTopics = getUniqueObjects(sortArr.map((el) => ({ topic_name: el.topic_name, topic_id: el.topic_id, index: el.index, complete: false })), 'topic_id');
            const topics = mergeArrays(uniquesTopics, stateCopy.chapterTopics);
            const content = topics.map(data => { return action.payload.data.sort((a, b) => (a.index > b.index) ? 1 : -1).filter(el => { return data.topic_name === el.topic_name }) });
            stateCopy.topics = getComplete(topics, content);
            stateCopy.resourceIndex = [].concat.apply([], content);
            stateCopy.resourcesCopy = stateCopy.resources;
            stateCopy.resources = content;
            stateCopy.subjectChapters.forEach(el => {
                if (el._id === action.payload.id) {
                    el.subtopics = stateCopy.resources;
                } else {
                    el.subtopics = []
                }
            })
            stateCopy.isShow = false;
            stateCopy.topicLoading = false;
            return stateCopy;
        case GET_INDIVIDUAL_RESOURCE:
            stateCopy.individualResource = action.payload;
            stateCopy.topic_name = action.payload.topic_name;
            // stateCopy.getIndividualResource = action.payload;
            // var found1 = stateCopy.filterPrevResources.find(o => o._id === action.payload._id);
            // if (found1 === undefined) {
            //     stateCopy.filterPrevResources = [...stateCopy.filterPrevResources, stateCopy.getIndividualResource];
            // }
            stateCopy.isShow = true;
            stateCopy.isLoadingResource = false;
            return stateCopy;
        case RESET_RESOURCE:
            stateCopy.individualResource = {};
            stateCopy.isLoadingResource = true;
            stateCopy.questions = [];
            stateCopy.qIndex = stateCopy.questions.findIndex(el => { return el.answer === null });
            return stateCopy;
        case LIKE_RESOURCE:
            stateCopy.individualResource.like = action.payload.like;
            return stateCopy;
        case GET_NOTE:
            if (action.payload.data !== null) {
                stateCopy.note = action.payload.data.text;
            }
            else {
                stateCopy.note = null;
            }
            return stateCopy;
        case SAVE_NOTE:
            stateCopy.note = action.payload.text;
            return stateCopy;
        case GET_QUIZ_DATA:
            stateCopy.isShow = true;
            stateCopy.individualResource = action.payload;
            stateCopy.questions = action.payload.questions;
            stateCopy.question = null;
            stateCopy.qIndex = action.payload.questions.findIndex(el => { return el.answer === null });
            return stateCopy;
        case GET_QUESTION:
            stateCopy.question = stateCopy.questions.filter(el => el._id === action.payload._id)[0];
            return stateCopy;
        case GET_ANSWER:
            stateCopy.question.correct = action.payload.data.correct;
            stateCopy.question.correct_id = action.payload.data.correct_id;
            stateCopy.question.answer = action.payload.aid;
            const answersns = stateCopy.questions.map(answer => {
                if (answer._id === action.payload.qid) return {
                    _id: answer._id,
                    title: answer.title,
                    description: answer.description,
                    type: answer.type,
                    skill: answer.skill,
                    liked: answer.liked,
                    options: answer.options,
                    correct_id: action.payload.data.correct_id,
                    correct: action.payload.data.correct,
                    answer: action.payload.aid
                }
                return answer
            })
            stateCopy.questions = answersns;
            return stateCopy;
        case QUIZ_SUBMIT:
            stateCopy.isQuizComplete = false;
            stateCopy.score = action.payload;
            const questions1 = stateCopy.questions.map(answer => {
                return {
                    _id: answer._id,
                    title: answer.title,
                    description: answer.description,
                    type: answer.type,
                    skill: answer.skill,
                    answers: answer.answers,
                    correct_id: null,
                    correct: null,
                    answer: null
                }
            })
            stateCopy.questions = questions1;
            // stateCopy.showQuiz = false;
            // stateCopy.qIndex = 0;
            return stateCopy;
        case CLOSE_QUIZ:
            stateCopy.isShow = false
            return stateCopy;

        case ANALYTIC_CAPTURE:
            stateCopy.resources.forEach((el) => {

                if (el._id === action.payload.resource_id) {
                    el.viewed = true
                }
            })
            return stateCopy;

        case BACK_TO_TOPIC:
            stateCopy.isShow = false;
            return stateCopy;




        case GET_STUDENT_DETAILS:
            stateCopy.program_id = '61335b19e11e2f5e9098bfc4';
            stateCopy.semLoading = false;
            let arr = [];
            let len = 6;
            for (let i = 0; i < len; i++) {
                if (6 === i + 1) {
                    arr.push({
                        title: 'S' + (i + 1),
                        active: true
                    });
                }
                else {
                    arr.push({
                        title: 'S' + (i + 1),
                        active: false
                    });
                }
            }
            stateCopy.sems = arr;
            return stateCopy;
        case GET_OTHER_PROGRAMS:
            // stateCopy.programs = action.payload;
            stateCopy.isProgramLoading = false;
            return stateCopy;
        case GET_SEMESTERS:
            stateCopy.program_id = '61335b19e11e2f5e9098bfc4';
            stateCopy.isSubjectLoading = false;
            stateCopy.semsArr = [...stateCopy.semsArr, action.payload.data];
            return stateCopy;
        case RESET_SUBJECT:
            stateCopy.subject = [];
            return stateCopy;
        case GET_ACTIVE_SEM:
            stateCopy.sems.forEach((el) => {
                if (el.title === action.payload.sem) {
                    el.active = true;
                }
                else {
                    el.active = false;
                }
            });
            return stateCopy;
        default:
            return stateCopy;
    }
}

var temp

const getComplete = (topics, content) => {
    for (var i = 0; i < topics.length; i++) {
        var contentIndex = content[i];
        for (var j = 0; j < contentIndex.length; j++) {
            temp = true;
            if (topics[i].topic === contentIndex[j].topic) {
                if (!contentIndex[j].viewed) {
                    temp = false;
                    break;
                }
            }
        }
        topics[i].complete = temp
    }
    return topics
}
