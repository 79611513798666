import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TopicLoader } from '../../Loader/loader';
import { convertDate } from '../../utils/miscelleneous';
import './reports.scss';

export default function HistoryPopup(props) {
    return (
        <div className='history-popup' style={{ width: props.type === 'score' ? '400px' : '600px' }}>
            <div className='history-header'>
                <h1>{props.type === 'score' ? 'Engagement history' : props.type === 'attendance' ? 'Attendance History' : props.testHistory?.title}</h1>
                <div className='cross-holder' style={{ right: 0 }} onClick={() => props.closePopup()}>
                    <img src='/images/close2.png' alt='' />
                </div>
            </div>
            <div className='history-details'>
                {
                    props.type === 'score' ?
                        <div className='rewards-history'>
                            <ul style={{
                                maxHeight: window.innerWidth > 767 ? 400 : 510,
                                overflow: 'auto',
                                overflowX: 'hidden', borderBottomLeftRadius: 4, borderBottomRightRadius: 4
                            }} id="scrollableDiv1">
                                <li className='reward-entry'>
                                    <h1>Activity</h1>
                                    <h1 className='float-right' style={{ marginRight: -10 }}  >Points</h1>
                                </li>
                                <InfiniteScroll
                                    dataLength={props.scoreHistory !== undefined && props.scoreHistory.length} //This is important field to render the next data
                                    next={props.fetchData}
                                    hasMore={true}

                                    endMessage={
                                        <p style={{ textAlign: 'center' }}>
                                            <b>Yay! You have seen it all</b>
                                        </p>
                                    }
                                    style={{ overflow: 'unset' }}
                                    scrollableTarget="scrollableDiv1"
                                >{
                                        props.ishistoryLoading ?
                                            <TopicLoader />
                                            :
                                            props.scoreHistory !== undefined && props.scoreHistory.length > 0 ?
                                                props.scoreHistory.map((history, index) => {
                                                    return (
                                                        <li className="reward-entry">
                                                            <div>
                                                                <h1 style={{ fontWeight: 'normal' }}>{convertDate(history.date)}</h1>
                                                                <h1 style={{ fontWeight: 'normal' }}>{
                                                                    history?.action?.includes('Video') ? <img src='/images/video-icon.svg' alt='' /> :
                                                                        history.action?.includes('Document') ? <img src='/images/document-icon.svg' alt='' /> :
                                                                            history.action?.includes('Quiz') ? <img src='/images/quiz-icon.svg' alt='' /> :
                                                                                history.action?.includes('Article') ? <img src='/images/article-icon.svg' alt='' /> :
                                                                                    history?.action.includes('Login') ? <img src='/images/practise-layout-icon.svg' alt='' /> :
                                                                                        history?.action.includes('Practice Test') ? <img src='/images/notes-layout-icon.svg' alt='' />
                                                                                            : null}
                                                                    &nbsp;&nbsp;{history?.action}</h1>
                                                            </div>
                                                            <h1 className='credit' style={{ color: '#696873' }}>
                                                                {history.points}
                                                            </h1>
                                                        </li>
                                                    )
                                                })
                                                :
                                                <h2 className='text-center mt-3' style={{ fontSize: 20 }}>There is no Activity </h2>
                                    }


                                </InfiniteScroll>
                            </ul>
                        </div> :
                        props.type === 'test-history' ?
                            <div className='rewards-history'>
                                <ul style={{ maxHeight: window.innerWidth > 767 ? 400 : 510, overflow: 'auto' }} id='scrollableDiv1' >
                                    <li className='reward-entry'>
                                        <h1 style={{ width: '48%' }}>Student Name</h1>
                                        <h1>Section</h1>
                                        <h1>last score</h1>
                                        {window.innerWidth > 767 ? <h1>Date</h1> : null}
                                    </li>
                                    {
                                        props.ishistoryLoading ?
                                            <TopicLoader />
                                            :
                                            props.testHistory?.quiz_details !== undefined && props.testHistory?.quiz_details?.length > 0 ?
                                                props.testHistory?.quiz_details.map((history, index) => {
                                                    return (
                                                        <li className="reward-entry">
                                                            <h1 className='credit' style={{ width: '58%', color: '#696873' }}>
                                                                {history.user_name}
                                                            </h1>
                                                            <h1 className='credit' style={{ width: window.innerWidth > 767 ? '20%' : '32%', color: '#696873' }}>
                                                                {history.section_name}
                                                            </h1>
                                                            <div style={{ textAlign: 'center', width: '10%' }}>
                                                                <h1 style={{ fontWeight: 'normal', }}>{history.last_attempt?.correct}</h1>
                                                            </div>
                                                            {window.innerWidth > 767 ? <div style={{ width: '15%', textAlign: 'center' }}>
                                                                <h1 style={{ fontWeight: 'normal' }}>{convertDate(history.last_attempt?.createdAt)}</h1>
                                                            </div> : null
                                                            }

                                                        </li>
                                                    )
                                                })
                                                :
                                                <h2 className='text-center mt-3' style={{ fontSize: 20 }}>There is no  history </h2>
                                    }
                                </ul>
                            </div>
                            :
                            <div className='rewards-history'>
                                <ul style={{ maxHeight: window.innerWidth > 767 ? 400 : 510, overflow: 'auto' }} id='scrollableDiv1' >
                                    <li className='reward-entry'>
                                        <h1 style={{ width: '50%' }}>Class Name</h1>
                                        <h1  >Date</h1>
                                        <h1>Present</h1>
                                    </li>
                                    {
                                        props.ishistoryLoading ?
                                            <TopicLoader />
                                            :
                                            props.scoreHistory !== undefined && props.scoreHistory.length > 0 ?
                                                props.scoreHistory.map((history, index) => {
                                                    return (
                                                        <li className="reward-entry">
                                                            <h1 className='credit' style={{ width: '50%', color: '#696873' }}>
                                                                {history.class}
                                                            </h1>
                                                            <div style={{ width: '30%', textAlign: 'center' }}>
                                                                <h1 style={{ fontWeight: 'normal' }}>{convertDate(history.date)}</h1>
                                                            </div>
                                                            <div style={{ textAlign: 'center', width: '10%' }}>
                                                                <h1 style={{ fontWeight: 'normal', }}>{history.present ? 'Yes' : 'No'}</h1>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                                :
                                                <h2 className='text-center mt-3' style={{ fontSize: 20 }}>There is no Attendance history </h2>
                                    }
                                </ul>
                            </div>
                }
            </div>
        </div >
    )
}
