/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, Fragment } from 'react';
import Slider from 'react-slick';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import CircularProgressBar from '../../utils/circularProgressBar';
import 'react-circular-progressbar/dist/styles.css';
import './dashboard.scss';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { BallBeat } from '../../Loader/loader';
import { getStudentLogin, getCompletions, getEngagementScore, getCountsSubject, getTopicMatrics } from '../../Redux/ActionCreators/dashboardActions';
import { getMonthlyGraph } from '../../Redux/ActionCreators/achieverAction';
import { reverseArr, SampleNextArrow, SamplePrevArrow } from '../../utils/miscelleneous';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
let emptyMainArray = []



function DashboardModule() {
    const [isBannerLoader, setIsBannerLoader] = useState(false);
    const sliderRef = React.useRef(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { studentTrend, subjects, subjectComplete, subjectCount, testComplete, testCount, scores, engagement_score, contentCounts, topicMatrics } = useSelector(state => state.dashboard);
    const { months, consumed, } = useSelector(state => state.achiever);
    const { profileDetails } = useSelector(state => state.profile);

    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        dispatch(getStudentLogin())
        dispatch(getTopicMatrics());
        dispatch(getMonthlyGraph(''));
        dispatch(getCompletions());

        document.body.classList.add('dashboard-show-we-chat');

        return () => {
            document.body.classList.remove('dashboard-show-we-chat');
        }
    }, [])


    scores.map((items, i) => {
        if (items > 0) {
            let maxValue = Math.max(...scores) + 20
            return emptyMainArray.push(Math.round(maxValue / 10) * 10)
        }

    })

    const engagementGraph = {
        chart: {
            type: 'column',
            height: 270,
        },
        legend: {
            enabled: false
        },

        title: {
            text: ''
        },

        colors: ['#5EB5E7'],
        xAxis: {
            categories: months !== undefined && months,
            type: 'Months',
        },
        yAxis: {
            type: 'Scores',
            min: 0,
            max: emptyMainArray[0] - 10,
            tickInterval: 30,
            visible: false,
            title: {
                text: ''
            },

        },
        plotOptions: {
            series: {
                animation: false,
                dataLabels: {
                    enabled: true,
                    crop: false,
                    overflow: false,
                    formatter: function () {
                        // eslint-disable-next-line eqeqeq
                        if (this.y != 0) {
                            return this.y;
                        }
                    }

                }
            }
        },
        credits: {
            enabled: false
        },
        series: [
            {
                enableMouseTracking: false,
                showInLegend: false,
                grouping: false,
                color: '#008080',
                data: scores !== undefined && scores,
            }
        ]
    }

    const monthlyWise = {
        chart: {
            type: 'column',
            height: 190
        },
        legend: {
            enabled: false
        },
        tooltips: {
            enabled: false
        },
        title: {
            text: ''
        },
        colors: ['#5EB5E7', '#00B24B', '#ECC30B', '#FF5A5F'],
        xAxis: {
            categories: months && months,
            type: 'Months'
        },
        yAxis: {
            type: 'Scores',
            min: 0,
            max: 100,
            tickInterval: 20,
            labels: {
                format: '{value}%'
            },
            title: {
                text: ''
            }
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            },
            series: {
                animation: false,
                enableMouseTracking: false,
                hover: {
                    enabled: false
                }

            }
        },
        tooltip: {
            enabled: false
        },
        series: consumed !== undefined && consumed
    }
    var settings = {
        dots: false,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 3,
        nextArrow: <SampleNextArrow className={"next-arrow"} />,
        prevArrow: <SamplePrevArrow className={"prev-arrow"} />,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 1008,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const allValuesAreZero = consumed?.every(item => item.data?.every(value => value === 0));

    var topicCompleted = topicMatrics !== undefined && ((topicMatrics.chapters_complete / topicMatrics.total_chapters) * 100);
    topicCompleted = topicCompleted ? topicCompleted : 0;

    var percent = studentTrend?.filter(el => el.login !== 0)
    var percentage = Math.round((percent?.length / (studentTrend?.length)) * 100);
    return (
        <div className='dashboard-container1'>
            <div className='slider-dashboard'>
                <div className="" style={{ width: "100%" }}>
                    <div className="static-holder">
                        <div className="cards-dashboard">
                            <div className="cards-column-details">
                                <h3>Current Class Analysis </h3>

                                {profileDetails !== undefined &&
                                    <Fragment>
                                        <h4>Class {profileDetails?.class}</h4>
                                        <p>Section : {profileDetails?.section?.name}</p>
                                        <p>Affiliation : {profileDetails?.affiliation?.name}</p>
                                    </Fragment>
                                }
                            </div>
                            <div className="cards-column">
                                {
                                    isBannerLoader ?
                                        <div className="loading-center-banner">
                                            <BallBeat color={'#F5821F'} />
                                        </div>
                                        :
                                        <Fragment>
                                            <div style={{ flex: 2 }}>
                                                <div className='topic-progress'>
                                                    <CircularProgressbarWithChildren value={topicCompleted !== undefined && topicCompleted}
                                                        styles={{
                                                            root: {
                                                                width: '100%',
                                                                fontSize: 10,
                                                                borderRadius: '50%',
                                                                background: '#F7F7FA',
                                                                verticalAlign: "middle",
                                                                position: 'relative'

                                                            },
                                                            path: {
                                                                strokeLinecap: 'round',
                                                                stroke: '#5EB5E7',
                                                                strokeWidth: 6,
                                                                padding: 0                                                                        // background:'true'

                                                            },
                                                            trail: {
                                                                stroke: '#DDDDE2',
                                                                strokeWidth: 6,
                                                                strokeLinecap: 'round',

                                                            },
                                                            text: {
                                                                fontSize: '33px',
                                                                fill: '#000000'
                                                            },
                                                        }}
                                                    >
                                                        <div className='d-flex flex-column justify-content-center align-items-center'>
                                                            <strong className='progress-text' >{topicCompleted !== undefined && Math.round(topicCompleted)}%</strong>
                                                            <br />
                                                            <span style={{ marginTop: -10 }}>Topics <br /> Complete</span>
                                                        </div>
                                                    </CircularProgressbarWithChildren>
                                                </div>
                                            </div>
                                            <div style={{ flex: 2, marginTop: window.innerWidth > 767 ? 0 : 30 }}>
                                                <div className="chart-cards">
                                                    <h4>{topicMatrics?.subjects}</h4>
                                                    <div className='current-subjects' >
                                                        <img src='/images/subjects.svg' alt='' />
                                                        <div style={{ marginLeft: 20 }} >
                                                            <p>Total subjects</p>
                                                            <p>(Current Section)</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="chart-cards">
                                                    <h4>{topicMatrics?.total_chapters}</h4>
                                                    <div className='current-subjects' >
                                                        <img src='/images/topics.svg' alt='' />
                                                        <div style={{ marginLeft: 20 }} >
                                                            <p>Total Topics</p>
                                                            <p>(Current Section)</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="chart-cards">
                                                    <h4>{topicMatrics?.chapters_complete}</h4>
                                                    <div className='current-subjects' >
                                                        <img src='/images/topic-complete.svg' alt='' />
                                                        <div style={{ marginLeft: 20 }} >
                                                            <p>Topics completed</p>
                                                            <p>(Current Section)</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='content-consume mt-2'>
                <div className="row w-100">
                    <div className="col-lg-6 col-sm-6" style={{ paddingRight: window.innerWidth > 767 ? 10 : 0 }}>
                        <div className='subject-consume'>
                            <div className='legand-box'>
                                <h3>Last 10 days login trend
                                </h3>
                                <div className={window.innerWidth > 767 ? 'legend-box d-flex' : 'legend-box d-flex w-100'}>
                                    <div className='legend px-3 d-flex align-items-center'>
                                        <div className='legend-circle' style={{ background: '#00B24B' }}></div>&nbsp; LoggedIn
                                    </div>
                                    <div className='legend px-3 d-flex align-items-center'>
                                        <div className='legend-circle' style={{ background: '#FF5A5F' }}></div>&nbsp; Absent
                                    </div>
                                </div>
                            </div>
                            <div className='student-trend row justify-content-center'>
                                {/* <p className='mt-5' >
                                                                                        Data will be available after 15 days

                                                                                    </p> */}
                                <div className='col-md-3 mt-4 login-percent'>
                                    <h4>{percentage + '%'}</h4>
                                    <span>{percent && percent.length}/10 Days</span>
                                </div>
                                <div className='col-md-9 d-flex flex-wrap justify-content-center'>
                                    {
                                        studentTrend !== undefined && studentTrend.map(el => {
                                            return (
                                                <div className='circle mt-3' style={{ background: el.login === 0 ? '#FF5A5F' : '#00B24B' }}>
                                                    {el.date}
                                                </div>
                                            )
                                        })

                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 ps-md-0 pl1 pr-md-2 " style={{ marginTop: window.innerWidth > 767 ? 0 : 8 }}>
                        <div className='month-consume'>
                            <div className='legand-box'>
                                <h3>Monthly content consumption
                                    <p>Percentage distribution of items consumed</p>
                                </h3>
                                <ul className='legands float-right'>
                                    <li><div className='div-width' style={{ background: '#5EB5E7' }}></div>&nbsp; Article</li>
                                    <li><div className='div-width' style={{ background: '#00B24B' }}></div>&nbsp; Video</li>
                                    <li><div className='div-width' style={{ background: '#ECC30B' }}></div>&nbsp; Quiz</li>
                                    <li><div className='div-width' style={{ background: '#FF5A5F' }}></div>&nbsp; Document</li>
                                </ul>
                            </div>
                            <div className='subject-chart'>
                                {allValuesAreZero ?
                                    <p className='text-center pt-4' style={{ fontWeight: 600 }}>Data is available after 10 days</p> :
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={monthlyWise}
                                        oneToone={true}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='content-consume mt-2'>
                <div className="row w-100">
                    <div className='col-12' style={{ paddingRight: window.innerWidth > 767 ? 8 : 0 }}>
                        <div className='subject-consume1'>
                            <div className={window.innerWidth > 767 ? 'legand-box w-100 ' : 'legand-box w-100 flex-wrap'} style={{ padding: 20, paddingBottom: 0, borderRadius: 4 }}>
                                <h3 className='w-100'>Subject wise completion & practice test status
                                </h3>
                            </div>
                            <div className='legend-status'>
                                <div className='legend'>
                                    <div className='legend-circle' style={{ background: '#ECC30B' }}></div>&nbsp; Subject completion status
                                </div>
                                <div className='legend'>
                                    <div className='legend-circle' style={{ background: '#00B24B' }}></div>&nbsp; Practice  test status
                                </div>

                            </div>
                            <div className='progres-test'>
                                {
                                    subjects !== undefined && !subjects?.length ?
                                        <div className="empty-list" style={{ height: '188px' }}>
                                            {/* <img src="/images/empty.png" alt="" width='100px' height='100px' /> */}
                                            <p>Subject  is not available for this class</p>
                                        </div>
                                        :
                                        (window.innerWidth > 767 ?
                                            <div className='w-100'>

                                                {subjects?.length > 2 ?
                                                    <Slider {...settings} ref={(c) => (sliderRef)}>
                                                        {
                                                            subjects?.map((el, index) => {
                                                                return (
                                                                    <div className="card w-95">
                                                                        <div className="card-body text-center p-0">
                                                                            <div className='card-overlay' >

                                                                            </div>
                                                                            <div
                                                                                className="d-flex flex-column align-items-center"
                                                                                style={{ padding: "15px 12px" }}
                                                                            >
                                                                                <h1>{el.name}</h1>
                                                                                <div className="mt-2">
                                                                                    <CircularProgressBar chapterComplete={subjectComplete[index]} testComplete={testComplete[index]} />
                                                                                </div>
                                                                                <div className='status'>
                                                                                    <div className='d-flex flex-column align-items-center'>
                                                                                        <h2>{Math.round(subjectComplete[index])}%</h2>
                                                                                        <h3>{subjectCount[index]}</h3>
                                                                                    </div>&nbsp;
                                                                                    <div className='d-flex flex-column align-items-center'>
                                                                                        <h2 style={{ color: '#00B24B' }}>{Math.round(testComplete[index])}%</h2>
                                                                                        <h3>{testCount[index]}</h3>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })

                                                        }
                                                    </Slider> :
                                                    <div className='d-flex'>
                                                        {subjects?.slice(0, 2)?.map((el, index) => {
                                                            return (
                                                                <div className="card" style={{ width: 185, marginRight: 10 }}>
                                                                    <div className="card-body text-center p-0">
                                                                        <div className='card-overlay' >

                                                                        </div>
                                                                        <div
                                                                            className="d-flex flex-column align-items-center"
                                                                            style={{ padding: "15px 12px" }}
                                                                        >
                                                                            <h1>{el.name}</h1>
                                                                            <div className="mt-2">
                                                                                <CircularProgressBar chapterComplete={subjectComplete[index]} testComplete={testComplete[index]} />
                                                                            </div>
                                                                            <div className='status'>
                                                                                <div className='d-flex flex-column align-items-center'>
                                                                                    <h2>{Math.round(subjectComplete[index])}%</h2>
                                                                                    <h3>{subjectCount[index]}</h3>
                                                                                </div>&nbsp;
                                                                                <div className='d-flex flex-column align-items-center'>
                                                                                    <h2 style={{ color: '#00B24B' }}>{Math.round(testComplete[index])}%</h2>
                                                                                    <h3>{testCount[index]}</h3>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })
                                                        }
                                                    </div>
                                                }


                                            </div>
                                            :
                                            <Fragment>
                                                <div className='mobile-progress row flex-nowrap' style={{ overflowY: 'hidden' }}>
                                                    {subjects !== undefined &&
                                                        subjects.map((el, index) => {
                                                            return (
                                                                <div className="card"    >
                                                                    <div class="card-body text-center p-0">
                                                                        <div
                                                                            className="d-flex flex-column align-items-center"
                                                                            style={{ padding: "15px 12px" }}
                                                                        >
                                                                            <h1>{el.name}</h1>
                                                                            <div className="mt-2">
                                                                                <CircularProgressBar chapterComplete={subjectComplete[index]} testComplete={testComplete[index]} />
                                                                            </div>
                                                                            <div className='status'>
                                                                                <div className='d-flex flex-column align-items-center'>
                                                                                    <h2>{Math.round(subjectComplete[index])}%</h2>
                                                                                    <h3>{subjectCount[index]}</h3>
                                                                                </div>&nbsp;
                                                                                <div className='d-flex flex-column align-items-center'>
                                                                                    <h2 style={{ color: '#00B24B' }}>{Math.round(testComplete[index])}%</h2>
                                                                                    <h3>{testCount[index]}</h3>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}

                                                </div>
                                            </Fragment>)
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="engagment-section">
                <div className="row w-100">
                    <div className="col-lg-6 col-sm-6" style={{ paddingRight: window.innerWidth > 767 ? 10 : 0 }}>

                        <div className="engagement-score-main">
                            <h3>How well do you rank?</h3>
                            {profileDetails !== undefined && profileDetails.section_rank ? <div className={window.innerWidth > 767 ? 'd-flex justify-content-between' : 'd-flex justify-content-center flex-wrap'}>
                                <div className="engagment-points">
                                    <h1>{profileDetails.section_rank}</h1>
                                    <div style={{ marginLeft: 25 }}>
                                        <p> <img src='/images/section-rank.svg' style={{ verticalAlign: 'middle' }} alt='' /> &nbsp;Section Rank</p>
                                    </div>
                                </div>
                                <div className="program-rank">
                                    <div className="chart-cards">
                                        <h4>{profileDetails.program_rank}</h4>
                                        <div className='current-subjects' >
                                            <img src='/images/program-rank.svg' alt='' />
                                            <div >
                                                <p>Program Rank</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="chart-cards">
                                        <h4>{profileDetails.center_rank}</h4>
                                        <div className='current-subjects' >
                                            <img src='/images/section-rank1.svg' alt='' />
                                            <div >
                                                <p>Center Rank</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> :
                                <h4 style={{ marginTop: '20%', textAlign: 'center', marginBottom: '20%' }}>
                                    <img src='/images/alert-icon.svg' alt='' width='30px' /><br />
                                    Start learning. Your data will be available soon.</h4>
                            }

                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 pl-md-0 pl1 pr-md-2">
                        <div className="engagement-graph">
                            <div className='d-flex  justify-content-between align-items-center'>
                                <h3>Engagement score and Monthly data
                                </h3>
                                <small className='cursor-pointer' onClick={() => getScoreHistory()}><img src='/images/history1.svg' style={{ verticalAlign: 'middle' }} alt='' /> &nbsp;Engagement history </small>
                            </div>
                            <div className={window.innerWidth > 767 ? 'd-flex justify-content-center align-items-center row' : 'd-flex justify-content-center align-items-center row'}>
                                <div className='engagement-score col-md-4' >
                                    <h3>{engagement_score}</h3>
                                    <p>Engagement Score</p>
                                </div>
                                <div className='engagement-chart col-md-8' style={{ height: '40vh' }}>
                                    {
                                        scores !== undefined && scores.every(element => element === 0) ?
                                            <h4 className="engagement-update">
                                                Your data will be available after 24
                                                hours</h4> :
                                            <div>
                                                <HighchartsReact
                                                    highcharts={Highcharts}
                                                    options={engagementGraph}
                                                />
                                            </div>
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div> */}

        </div>
    )
}




export default DashboardModule;