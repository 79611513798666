import React, { Fragment, useRef, useEffect, useState } from 'react';
import '../contentLibrary.scss';
import { useNavigate, Link } from 'react-router-dom';
import { BubbleLoader } from '../../../Loader/loader';
import { useSelector } from 'react-redux';
import { getOtherSkills, getSearchResults, bookmarksSkill } from '../../../Redux/ActionCreators/contentLibraryAction';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';


export default function Skills() {
    const [search, setSearch] = useState('');

    const navigate = useNavigate();
    const { isSkillLoading, otherSkills } = useSelector(state => state.contentLibrary);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        const firstRender = async () => {
            await dispatch(getOtherSkills());
        }
        firstRender();
    }, [])

    const timerRef1 = React.useRef(null);

    const getQuery = (val) => {
        clearTimeout(timerRef1.current);
        setSearch(val);
        timerRef1.current = setTimeout(() => delaySearch(val), 800);
    }


    const delaySearch = async (val) => {
        await dispatch(getSearchResults(val));
    }


    const goBack = () => {
        navigate(-1);
    }


    const bookmarkSkill = async (bookmark, id) => {
        await dispatch(bookmarksSkill(bookmark, id));
        await dispatch(getOtherSkills());
    }


    return (
        <div className='subject-holder'>
            <div className="bc-nav  mt-2" style={{ marginLeft: -2 }}>
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        {/* <li className="breadcrumb-item"><img src='/images/home.svg' alt='' /></li> */}
                        <li className="breadcrumb-item cursor-pointer" onClick={() => navigate(-1)} >Content Library</li>
                        <li className="breadcrumb-item  b-active">Explore</li>
                    </ol>
                </nav>
            </div>
            <h1 className='d-flex justify-content-between align-items-center flex-wrap'>Explore Other skills
                <div className="has-search"  >
                    <span className="form-control-feedback" >
                        <img src="/images/search.svg" alt='' />
                    </span>
                    <input type="search" className="form-control" placeholder={t("Search skill")} onChange={(e) => getQuery(e.target.value)} />
                </div>
            </h1>
            <div style={{ width: '96%' }}>
                {
                    !otherSkills?.length ?
                        <BubbleLoader />
                        :
                        otherSkills?.length === 0 ?
                            <div className="empty-list">
                                <img src="/images/empty.png" alt="" />
                                <p>There is not added any Skills yet.</p>
                            </div>
                            :
                            <Fragment>
                                <div className=' mt-2'>
                                    <div className={window.innerWidth > 767 ? 'd-flex flex-wrap' : 'row flex-nowrap overflow-auto mr-2'}>
                                        {
                                            window.innerWidth > 767 ?

                                                isSkillLoading ?
                                                    <BubbleLoader /> :
                                                    otherSkills?.map((el, index) => {
                                                        return (
                                                            <div class="card" key={el._id} style={{ width: '18.5%', marginRight: 10 }} >
                                                                <Link to={`/content-library/chapters`}
                                                                    state={{
                                                                        id: el._id,
                                                                        img: el.image,
                                                                        type: 'main',
                                                                        title: el.title,
                                                                        detail: el,
                                                                        content: true
                                                                    }} >
                                                                    <img class="card-img-top" src={el.image} alt="Card" height='118px' />
                                                                    <div className='card-body p-0'>
                                                                        <div style={{ padding: '5px 7px' }}>
                                                                            <div className='d-flex justify-content-between flex-column' >
                                                                                <h5>
                                                                                    {el.title}
                                                                                </h5>
                                                                                <p><img src='/images/chapter.svg' alt='' /> &nbsp;&nbsp; {el.total} {t('Items')}</p>

                                                                                <div style={{ borderTop: '1px solid #ddd', marginTop: 0 }}></div>
                                                                                <h4>{t('Start learning')}
                                                                                    {
                                                                                        el.bookmarked ?
                                                                                            <img src='/images/saved.svg' alt='' width='18px' height='20px' onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                e.stopPropagation();
                                                                                                bookmarkSkill(el.bookmarked, el._id);
                                                                                            }} />
                                                                                            : <img src='/images/save.svg' alt='' width='18px' height='20px' onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                e.stopPropagation();
                                                                                                bookmarkSkill(el.bookmarked, el._id);
                                                                                            }} />
                                                                                    }
                                                                                </h4>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        )
                                                    })
                                                :
                                                otherSkills.map((el, index) => {
                                                    return (
                                                        <div class="card" key={el._id} style={{ minWidth: '195px', width: 260, marginRight: 10 }} >
                                                            <Link to={`/content-library/chapters`}
                                                                state={{
                                                                    id: el._id,
                                                                    img: el.image,
                                                                    type: 'main',
                                                                    title: el.title,
                                                                    detail: el,
                                                                    content: true
                                                                }} >
                                                                <img class="card-img-top" src={el.image} alt="Card" height='118px' />
                                                                <div className='card-body p-0'>
                                                                    <div style={{ padding: '5px 7px' }}>
                                                                        <div className='d-flex justify-content-between flex-column' >
                                                                            <h5>
                                                                                {el.title}
                                                                            </h5>
                                                                            <p><img src='/images/chapter.svg' alt='' /> &nbsp;&nbsp; {el.total} {t('Items')}</p>

                                                                            <div style={{ borderTop: '1px solid #ddd', marginTop: 0 }}></div>
                                                                            <h4>{t('Start learning')}
                                                                                {
                                                                                    el.bookmarked ?
                                                                                        <img src='/images/saved.svg' alt='' width='18px' height='20px' onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            e.stopPropagation();
                                                                                            bookmarkSkill(el.bookmarked, el._id);
                                                                                        }} />
                                                                                        : <img src='/images/save.svg' alt='' width='18px' height='20px' onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            e.stopPropagation();
                                                                                            bookmarkSkill(el.bookmarked, el._id);
                                                                                        }} />
                                                                                }
                                                                            </h4>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )
                                                })
                                        }
                                    </div>
                                </div>

                            </Fragment>

                }
            </div>
        </div>
    )
}
