import React, { Component, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import './reports.scss';
import { useDispatch } from 'react-redux';
import { getRedirectUrl } from '../../Redux/ActionCreators/accountAction';



export default function TableComponent(props) {

    const [data, setData] = useState([]);
    const [asc, setAsc] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setData(props.centerList);
    }, [])

    console.log(props.activeType)

    const openIndividualItem = async (type, details) => {
        window.scrollTo(0, 0);
        window.onbeforeunload = function () {
            window.scrollTo(0, 0);
        };
        type !== 'school' && await props.openIndividualItem(type, details);
        if (type === 'school') {
            let obj = { school_id: details?._id }
            const res = await dispatch(getRedirectUrl(obj))
            if (res?.type === 'GET_URL') {
                window.open(res.payload, '_blank')
            }
            // props.getSchoolStudentTrend(details?._id, 'student');
            // props.getSchoolScoreTrend(details?._id, 'student');
            // props.getSchoolStudents(details?._id, 'student', 1, 'total_engagement', '', '');
            // props.getSchoolId(details?._id);
        }
        else if (type === 'student') {
            await props.resetStudentDetails();
            await props.getStudentId(details._id)
            await props.getStudentReportDetail(details._id);
            await props.getStudentReportDetail1(details._id);
        }
        if (type === 'studentAssessment') {
            await props.resetStudentDetails();
            await props.getStudentId(details._id);
            if (props.activeType?.name === 'riasec') {
                await props.getAssessmentReport(details._id);
            } else {
                await props.getUserAbility(details._id, props.activeType?._id);
            }
        }
        else if (type === 'section') {
            await props.setShow(false)
            await props.resetSectionDetails();
            await props.getSecStudent(details._id);
            await props.setShow(true);
        }
        else if (type === 'course') {
            await props.setShow(false)
            await props.resetProgramDetails();
            await props.getStudentSemester(details._id);
            await props.getTopEngagaedSemester(details._id);
            await props.getProgramSemesterData(details._id)
            await props.setShow(true)
        }
        else if (type === 'center') {
            await props.setShow(false);
            await props.resetCenterDetails();
            await props.getTopCenterPrograms(details._id);
            await props.getCenterProgramDetails(details._id);
            await props.setShow(true);
        }
        else if (type === 'subject') {
            await props.setShow(false);
            localStorage.setItem('subDetails', JSON.stringify(details))
            await props.resetSubjectDetails();
            await props.getTopChapterCompletion(details.subject_id, details.course_id);
            await props.getAllChapterCompletion(details.subject_id, details.course_id);
            await props.getTopSectionChapterCompletion(details.subject_id, details.course_id);
            await props.getAllSectionChapterCompletion(details.subject_id, details.course_id);
            await props.setShow(true);
        }
        else if (type === 'adminfaculty') {
            await props.setShow(false);
            await props.resetFacultyDetails();
            await props.getFacultyLogins(details._id)
            await props.getFacultyContentCount(details._id)
            await props.getFacultySubject(details._id)
            await props.getEngagementScores(details._id)
            await props.setShow(true);
        }
        else if (type === 'adminsubject') {
            await props.setShow(false);
            await props.resetFacultySubjectChapter();
            await props.getTopChapterCompletion(details._id, details.program_id);
            await props.getAllChapterCompletion(details._id, details.program_id);
            await props.getTopSectionChapterCompletion(details._id, details.program_id);
            await props.getAllSectionChapterCompletion(details._id, details.program_id);
            await props.setShow(true);
        }
        else if (type === 'facultySubject') {
            await props.setShow(false);
            await props.resetFacultySubjectChapter();
            await props.getTopChapterCompletion(details._id, details.program_id);
            await props.getAllChapterCompletion(details._id, details.program_id);
            await props.getTopSectionChapterCompletion(details._id, details.program_id);
            await props.getAllSectionChapterCompletion(details._id, details.program_id);
            await props.setShow(true);
        }

        else if (type === 'subject2') {
            // await props.setShow(true, details._id);
            // await props.getCourseDetails(details?._id);
        }

    }

    const onSort = async (sortKey, data1, type) => {
        await setAsc(!asc);
        switch (type) {
            case 'center':
                props.sortCenterList(sortKey, asc);
                break;
            case 'program1':
                props.sortCenterProgramList(sortKey, asc);
                break;
            case 'program':
                props.sortProgramList(sortKey, asc);
                break;
            case 'semester':
                props.sortProgramSemList(sortKey, asc);
                break;
            case 'section':
                props.sortSectionList(sortKey, asc);
                break;
            case 'subject':
                props.sortSubjectList(sortKey, asc);
                break;
            case 'adminfaculty':
                props.sortFacultyList(sortKey, asc);
                break;
            case 'adminsubject':
                props.sortFacultySubjectList(sortKey, asc);
                break;
            case 'facultySubject':
                props.sortFacultySubjectList(sortKey, asc);
                break;
            default:
                break;

        }
    }

    const { centerList, header, type } = props
    console.log(props.centerList, type)
    return (
        <div>
            <div class="table-admin">
                <Table responsive
                    data-sort-name="Total Engagement Score"
                    data-sort-order="desc"
                >
                    <thead>
                        <tr className="header-table">
                            {
                                header.map((el, i) => {
                                    return (
                                        <th style={{ textAlign: i === 0 ? 'left' : 'center' }} className={el.sort ? 'cursor-pointer' : ''} onClick={() => el.sort ? onSort(el.title, centerList, type) : ''}>{el.label}{el.sort ? <i class="fa fa-sort ml-2" style={{ color: 'grey' }} aria-hidden="true"></i> : ''}</th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
                    <tbody className="table-body">
                        {
                            centerList !== "" && centerList !== undefined && centerList !== null && centerList.length > 0 ?
                                centerList.map((center, index) => {
                                    return (
                                        <tr key={index}>
                                            {
                                                // eslint-disable-next-line array-callback-return
                                                header.map((el, i) => {
                                                    if (el.title === Object.keys(center)[i]) {
                                                        return (
                                                            type === 'question' ?
                                                                < td style={{ textAlign: i === 0 ? '' : 'center', cursor: 'auto', width: (type === 'section' && i === 2) ? '10%' : ((type === 'question') && el.title === 'title') ? '50%' : 'auto', color: '#696873' }}   >
                                                                    {((type === 'question') && el.title === 'title') ? <p dangerouslySetInnerHTML={{ __html: center[Object.keys(center)[i]] }} /> : center[Object.keys(center)[i]]}
                                                                </td> :
                                                                type === 'semester' || type === 'program1' || type === 'student1' || type === 'subject1' || type === 'facultySubject1' || type === 'course' || type === 'studentAssessment' || type === 'schoolStudent' || type === 'adminsubject' || type === 'exambytrainee' ?
                                                                    < td style={{ textAlign: i === 0 ? '' : 'center', cursor: 'auto', width: type === 'section' && i === 2 ? '10%' : 'auto', color: '#696873' }}   >
                                                                        {((type === 'semester' || type === 'center' || type === 'course' || type === 'section') && el.title === 'avg_score') ? (center[Object.keys(center)[i]] && center[Object.keys(center)[i]].toFixed(2)) : ((el.title === 'avg_score' || el.title === 'completion')) ? `${center[Object.keys(center)[i]]}%` : ((type === 'studentAssessment') && (el.title === 'view')) ? <img src='/images/view_grey.svg' className='cursor-pointer' width={20} alt='' onClick={() => openIndividualItem(type, center)} /> : center[Object.keys(center)[i]]}
                                                                    </td> :
                                                                    <td style={{ textAlign: i === 0 ? 'left' : 'center', width: type === 'section' && i === 2 ? '10%' : 'auto' }} title={(el.title === 'count' && type === 'course') ? `${center['specialization']}` : null} onClick={() => (i === 0 && type !== 'course' && type !== 'studentAssessment') ? openIndividualItem(type, center) : null}  >
                                                                        {((type === 'semester' || type === 'center' || type === 'program' || type === 'section') && el.title === 'avg_score') ? (center[Object.keys(center)[i]] && center[Object.keys(center)[i]]?.toFixed(1)) : (type === 'adminfaculty' && el.title === 'subject') ? center[Object.keys(center)[i]]?.map(el => el).join(', ') : center[Object.keys(center)[i]]}
                                                                    </td>
                                                        )
                                                    }
                                                })
                                            }
                                        </tr>
                                    )
                                })
                                :
                                null
                        }
                    </tbody>
                </Table>
            </div>
        </div >
    )
}
