import { GET_MENUS, GET_POPUP_NOTIFICATION } from "../Types/types";

let intialState = {
    menuList: []
}


export default function layoutReducer(state = intialState, action) {
    let stateCopy = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GET_MENUS:
            stateCopy.menuList = ((localStorage.getItem('role') === 'super-admin') || localStorage.getItem('role') === 'master-admin') ? action.payload : ((JSON.parse(localStorage.getItem('affiliations'))?.length > 1) ? action.payload.filter(el => el.action !== 'my-subjects') : action.payload);
            return stateCopy;
        case GET_POPUP_NOTIFICATION:
            stateCopy.notifications = action.payload.data;
            stateCopy.unread = action.payload.unread;
            return stateCopy;
        default:
            return stateCopy;
    }
}
